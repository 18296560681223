import { AxiosResponse } from 'axios';
import { decamelize } from 'humps';
import checkrApiClient from 'api/checkrApiClient';
import TokenNotProvidedError from 'api/tokenNotProvidedError';
import { getAPIBase } from './getAPIBase';
import { BACKEND_FLAG_PATHS } from '../../constants';
import {
  FetchReportParams,
  PostDisputeIntakeParams,
  ReportReponse,
  UpdateCandidateBody,
  UpdateCandidateParams,
} from './types';

const decamelizeOneDepthKeys = (params: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(params).map(([key, value]) => [decamelize(key), value]),
  );

const convertParamsToBody = ({
  candidateId: _candidateId,
  ...candidateOmittedId
}: UpdateCandidateParams): UpdateCandidateBody => {
  return decamelizeOneDepthKeys(
    candidateOmittedId,
  ) as unknown as UpdateCandidateBody;
};

export const patchCandidate = async (
  params: UpdateCandidateParams,
): Promise<{ [key: string]: any }> => {
  const userAccessToken = window.sessionStorage.getItem('token');
  const baseUrl = await getAPIBase({ path: BACKEND_FLAG_PATHS.CANDIDATES_ID });

  return checkrApiClient.patch(`${baseUrl}/candidates/${params.candidateId}`, {
    ...convertParamsToBody(params),
    token: userAccessToken,
  });
};

export const postDisputeIntake = async (
  params: PostDisputeIntakeParams,
): Promise<
  AxiosResponse<{
    [key: string]: string;
  }>
> => {
  const baseUrl = await getAPIBase({ path: BACKEND_FLAG_PATHS.DISPUTE_INTAKE });

  return checkrApiClient.post<{
    [key: string]: string;
  }>(`${baseUrl}/dispute_intake`, decamelizeOneDepthKeys(params));
};

export const fetchReport = async ({
  id,
  token,
}: FetchReportParams): Promise<AxiosResponse<ReportReponse>> => {
  if (!token) throw new TokenNotProvidedError();

  const reportsPath = '/reports/';

  const baseUrl = await getAPIBase({ path: BACKEND_FLAG_PATHS.REPORTS_ID });

  return checkrApiClient.get(`${baseUrl}${reportsPath}${id}`, {
    params: { token },
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
};
